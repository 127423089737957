document.addEventListener('DOMContentLoaded', () => {
    'use strict'

    // The autoComplete.js Engine instance creator
    const autoCompleteJS = new autoComplete({
        data: {
            src: async () => {
                try {
                    // Loading placeholder text
                    document
                        .getElementById("autoComplete")
                        .setAttribute("placeholder", "جاري التحميل...");
                    // Fetch External Data Source
                    const source = await fetch(
                        "../json/sugg.json"
                    );
                    const data = await source.json();
                    // Post Loading placeholder text
                    document
                        .getElementById("autoComplete")
                        .setAttribute("placeholder", autoCompleteJS.placeHolder);
                    // Returns Fetched data
                    return data;
                } catch (error) {
                    return error;
                }
            },
            keys: ["مقالة", "تصنيف", "منتج"],
            cache: true,
            filter: (list) => {
                // Filter duplicates
                // incase of multiple data keys usage
                const filteredResults = Array.from(
                    new Set(list.map((value) => value.match))
                ).map((issue) => {
                    return list.find((value) => value.match === issue);
                });

                return filteredResults;
            }
        },
        placeHolder: "ابحث عن موضوع أو سؤال...",
        resultsList: {
            element: (list, data) => {
                const info = document.createElement("p");
                info.classList.add("status_result");
                if (data.results.length > 0) {
                    info.innerHTML = `عرض <strong>${data.results.length}</strong> من اجمالي <strong>${data.matches.length}</strong> نتائج`;
                } else {
                    info.innerHTML = `تم العثور على  <strong>${data.matches.length}</strong> نتائج مطابقة <strong>"${data.query}"</strong>`;
                }
                list.prepend(info);
            },
            noResults: true,
            maxResults: 15,
            tabSelect: true
        },
        resultItem: {
            element: (item, data) => {
                // Modify Results Item Style
                item.style = "display: block;";
                // Modify Results Item Content
                item.innerHTML = `
                <a href="article-details.html" class="link_result_search">
                    <span class="issue_name">${data.match}</span> 
                    <span class="type_name">
                        ${data.key}
                    </span>
                </a>
                `;
            },
            highlight: true
        },
        events: {
            input: {
                focus: () => {
                    if (autoCompleteJS.input.value.length) autoCompleteJS.start();
                }
            }
        }
    });

}); //End. DOMContentLoaded
